.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 10s linear; */
    height: 10vmin;
    pointer-events: none;
  }
}

/* .App-header {
  min-height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: calc(10px + 2vmin);
} */

.App-link {
  color: #61dafb;
}


.claimsCard {
  display: flex;
  justify-content: space-around;
  padding: 5px;
}

.claimID{
  font-weight: bold;
}

.image-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.authenticator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 10;
}
